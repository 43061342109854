import React, { useState, useEffect } from 'react'
import { Container, Input, Navbar, NavbarBrand, Nav, Form, Row, Col, Label, FormGroup, FormFeedback, Button, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { signUp, getCurrentUser } from '../../api/fb'
import { ToastsStore } from 'react-toasts'
import { getString, languages } from '../utils'
import { keys } from 'lodash'
import ReactCountryFlag from "react-country-flag"
import { Download } from '../download'

const logo = require('../../assets/rsz_logo-white.png')
const qs = require('query-string')

const renderFlag = (code) => {
    // console.log(code)

    const style = {
        width: '1em',
        height: '1em',
    }
    
    if (code === "en"){
        return <ReactCountryFlag style={style}
        countryCode="US" svg/>
    }
    else if (code === "zh" || code === "zht"){
        return <ReactCountryFlag style={style}
        countryCode="CN" svg />
    }
    else if (code === "ta"){
        return <ReactCountryFlag style={style}
        countryCode="IN" svg />
    }
    else if (code === "ms"){
        return <ReactCountryFlag style={style}
        countryCode="MY" svg />
    }
    else if (code === "bn"){
        return <ReactCountryFlag style={style}
        countryCode="BD" svg />
    }
}

export const SignUpForm = ({location, prevLanguage, dorm, formId}) => {
    const { lang = null } = qs.parse(location.search)

    // const [dorm, setDorm] = useState(null)
    const [language, setLanguage] = useState(prevLanguage)

    const [isCompleted, setIsCompleted] = useState(false)
    // const [dormInput, setDormInput] = useState('')

    // const [invalidParam, setInvalidParam] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isLoading, setIsLoading] = useState(true)

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("required"),
        lastName: Yup.string().required("required"),
        phoneNumber: Yup.string()
            .required("required")
            .matches(/^[0-9]*$/, "errorPhone"),
        unitNumber1: Yup.string().required("required"),
        unitNumber2: Yup.string().required("required"),
        permitNumber: Yup.string().required("required").min(8, "errorPermit"),
    })

    // const handleSubmitOrg = async () => {
    //     setIsLoading(true)

    //     const orgs = await getOrganization(dormInput)
    //     const orgId = keys(orgs).find(o => {
    //         const { searchID = '' } = orgs[o]

    //         return searchID.toLowerCase() === dormInput.trim().toLowerCase()
    //     })

    //     if (orgId){
    //         setDorm(orgId)
    //     }

    //     else {
    //         ToastsStore.error(getString("errorCode", language), 4000)
    //     }

    //     // const orgs = await getOrganization(dormInput)
    //     // console.log(orgs)

    //     setIsLoading(false)

    // }

    useEffect(() => {
        console.log(lang)
        if (!prevLanguage){
            if (lang === null || !languages[lang]){
                setLanguage("en")
            }
            else {
                setLanguage(lang)
            }
        }
    }, [lang, prevLanguage])

    // useEffect(() => {
    //     const checkDorm = async () => {

    //         const orgs = await getOrganization(dormId)
    //         const orgId = keys(orgs).find(o => {
    //             const { searchID = '' } = orgs[o]

    //             return searchID.toLowerCase() === dormId.trim().toLowerCase()
    //         })

    //         if (orgId){
    //             setDorm(orgId)
    //         }
    //         else {
    //             setInvalidParam(true)
    //         }

    //         setIsLoading(false)
    //     }

    //     if (dormId === null){
    //         setIsLoading(false)
    //         return
    //     }

    //     else {
    //         setIsLoading(true)
    //         checkDorm()
    //     }

    // }, [dormId])

    const renderForm = () => {
        // if (isLoading){
        //     return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
        //         <Spinner color="info" style={{ width: '5rem', height: '5rem' }}/>
        //     </div>
        // }

        // if (invalidParam){
        //     return <div>Code not found.</div>
        // }

        // if (dorm === null){
        //     return <div className="info-div">
        //             <Row>
        //                 <Col>
        //                     <Input disabled={isLoading} value={dormInput} onChange={e => setDormInput(e.target.value)} placeholder={getString("dormId", language)} />
        //                 </Col>
        //             </Row>
        //             <Row>
        //                 <Col style={{textAlign: "center", marginTop: 30}}>
        //                     <Button color="primary" disabled={dormInput === "" || isLoading} onClick={handleSubmitOrg}>{getString("submit", language)}</Button>
        //                 </Col>
        //             </Row>
        //         </div>
        // }

        if (isCompleted){
            return <Download language={language} />
        }

        const handleSubmitForm = async (values) => {
            setIsSubmitting(true)
            const user = getCurrentUser()

            const payload = {
                anonymousUID: user.uid,
                firstName: values.firstName,
                lastName: values.lastName,
                organization: dorm,
                phone: values.phoneNumber,
                countryCode: values.phoneCountryCode,
                permitNumber: values.permitNumber,
                unitNumber: values.unitNumber1 + "-" + values.unitNumber2,
                language,
                prefLang: values.prefLang,
                formId
            }

            const { success, message = '' } = await signUp(payload)

            if (success){
                setIsSubmitting(false)
                setIsCompleted(true)
            }
            else {
                ToastsStore.error(message, 4000)
                setIsSubmitting(false)
            }

            // console.log(payload)
            // setTimeout(() => {setIsSubmitting(false); setIsCompleted(true)}, 1000)
            
        }

        return <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                phoneNumber: '',
                phoneCountryCode: '+65',
                permitNumber: '',
                unitNumber1: '',
                unitNumber2: '',
                prefLang: language
            }}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
            >
                {({values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue}) => {

                    return <Form onSubmit={handleSubmit}>
                        <h3>{getString("details", language)}</h3>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label> {getString("firstName", language)} </Label>
                                    <Input disabled={isSubmitting} invalid={errors.firstName && touched.firstName} value={values.firstName} onChange={handleChange} onBlur={handleBlur} name="firstName" />
                                    { errors.firstName && touched.firstName ? <FormFeedback>{getString(errors.firstName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("lastName", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.lastName && touched.lastName} value={values.lastName} onChange={handleChange} onBlur={handleBlur} name="lastName" />
                                    { errors.lastName && touched.lastName ? <FormFeedback>{getString(errors.lastName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>{getString("permitNumber", language)}</Label>
                                            <Input disabled={isSubmitting} invalid={errors.permitNumber && touched.permitNumber} value={values.permitNumber} onChange={handleChange} onBlur={handleBlur} name="permitNumber" />
                                            { errors.permitNumber && touched.permitNumber ? <FormFeedback>{getString(errors.permitNumber, language)}</FormFeedback> : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <FormGroup>
                                            <Label>{getString("unit", language)}</Label>
                                            <Row>
                                                <Col lg={5} md={5} sm={5} xs={5}>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText> # </InputGroupText>
                                                        </InputGroupAddon>
                                                        
                                                        <Input 
                                                            disabled={isSubmitting} 
                                                            invalid={errors.unitNumber && touched.unitNumber} 
                                                            value={values.unitNumber} 
                                                            onChange={handleChange} 
                                                            onBlur={handleBlur} name="unitNumber1" />
                                                            { errors.unitNumber1 && touched.unitNumber1 ? <FormFeedback>{getString(errors.unitNumber1, language)}</FormFeedback> : null}
                                                    </InputGroup>
                                                </Col>
                                                <Col style={{display: "flex", padding: 0, justifyContent: "center", alignItems: "center"}} lg={1} md={1} sm={1} xs={1}>
                                                    -
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                    <InputGroup>
                                                        <Input 
                                                            disabled={isSubmitting} 
                                                            invalid={errors.unitNumber && touched.unitNumber} 
                                                            value={values.unitNumber} 
                                                            onChange={handleChange} 
                                                            onBlur={handleBlur} name="unitNumber2" />
                                                            { errors.unitNumber2 && touched.unitNumber2 ? <FormFeedback>{getString(errors.unitNumber2, language)}</FormFeedback> : null}
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("phone", language)}</Label>
                                    <Row>
                                        <Col sm={5} md={5} lg={5} xs={5}>
                                            <Input disabled={isSubmitting} type="select" name="phoneCountryCode" value={values.phoneCountryCode} onChange={handleChange} onBlur={handleBlur}>
                                                <option>+60</option>
                                                <option>+62</option>
                                                <option>+65</option>
                                                <option>+66</option>
                                                <option>+91</option>
                                            </Input>
                                        </Col>
                                        <Col sm={7} md={7} lg={7} xs={7}>
                                            <Input disabled={isSubmitting} type="tel" invalid={errors.phoneNumber && touched.phoneNumber} value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name="phoneNumber" />
                                            { errors.phoneNumber && touched.phoneNumber ? <FormFeedback>{getString(errors.phoneNumber, language)}</FormFeedback> : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>{getString("prefLang", language)}</Label>
                                            <Input disabled={isSubmitting} type="select" name="prefLang" value={values.prefLang} onChange={handleChange} onBlur={handleBlur}>
                                                <option value="en">English</option>
                                                <option value="ms">Malay (Bahasa Melayu)</option>
                                                <option value="ta">Tamil (தமிழ்)</option>
                                                <option value="bn">Bengali (বাংলা)</option>
                                                <option value="zh">Chinese (中文)</option>
                                                <option value="id">Indonesian (Bahasa Indonesia)</option>
                                                <option value="my">Burmese (ဗမာစာ)</option>
                                                
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 15}}>
                            <Col style={{textAlign: 'right'}}>
                                <Button type="submit" color="primary" disabled={isSubmitting}>{getString("submit", language)}</Button>
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
    }

    return <Container className="app-container">
        <Navbar color="light" light expand="md" className="default-nav">
            <Nav className="mr-auto">
                <NavbarBrand><img height={30} src={logo} alt="logo"/> </NavbarBrand>
            </Nav>
            <Nav>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText> {renderFlag(language)} </InputGroupText>
                    </InputGroupAddon>
                    <Input disabled={isSubmitting} type="select" value={language} onChange={e => setLanguage(e.target.value)}>
                        {keys(languages).map(value => {
                            const { name = '' } = languages[value]

                            return <option value={value} key={value}>{name} </option>
                        })}                        
                    </Input>
                </InputGroup>
            </Nav>
        </Navbar>
        <Container className="form-container">
            {
                renderForm()
            }
        </Container>

    </Container>

}