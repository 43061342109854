import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const fbConfig = {
  apiKey: "AIzaSyDuos0naCAQmVz2wEALEqStub5gqgfQrf0",
  authDomain: "red-dots.firebaseapp.com",
  databaseURL: "https://red-dots.firebaseio.com",
  projectId: "red-dots",
  storageBucket: "red-dots.appspot.com",
  messagingSenderId: "115927873020",
  appId: "1:115927873020:web:d40c0e421f48add2d6ae79",
  measurementId: "G-C0XBWH1BLQ"
}

const app = firebase.initializeApp(fbConfig)

const database = firebase.database(app)
const auth = firebase.auth(app)

auth.signInAnonymously().catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    
    console.log(errorCode, errorMessage)
    });

export const onAuthStateChange = (handler) => {
    auth.onAuthStateChanged(user => handler(user))
}
  
export const getCurrentUser = () => auth.currentUser

export const getPushKey = (nodePath = '/tmp') => getNodeRef(nodePath).push().key
const getNodeRef = (path) => database.ref(path)

export const waitForRPCResponse = (responseNodePath) => {
  return new Promise((resolve, reject) => {
    const nodeRef = getNodeRef(responseNodePath)
    const handler = (snap) => {
        const result = snap.val()

        if (result ){
            const { response = {}, success = '' } = result
            resolve({success, ...response})
            nodeRef.off('value', handler) // Listener is not required anymore.
        }
      }


    nodeRef.on('value', handler)

  })
}

export const fbUpdate = async (nodePath, payload) => {
    const nodeRef = getNodeRef(nodePath)
    nodeRef.update(payload)
  }

export const RPCCall = async (nodePath, payload) => {
    const pushKey = getPushKey(nodePath)
    const requestNodePath = `${nodePath}/request/${pushKey}`
    const responseNodePath = `${nodePath}/response/${pushKey}`
  
    // Place the request
    await fbUpdate(requestNodePath, payload)
  
    // Wait for the response.
    try {
      const RPCCallResponse = await waitForRPCResponse(responseNodePath)
      return RPCCallResponse
    } catch (err) {
      // TODO: Handle error gracefully.
      console.log(err)
      throw err.message;
    }
  }

export const signUp = async (payload) => {
    const result = await RPCCall("RPC/selfServiceOnboard", {...payload, createdAt: firebase.database.ServerValue.TIMESTAMP})

    const { message, success } = result

    if (success){
        return {
            success
        }
    }
    else {
        return {
            success,
            message
        }
    }
}

export const getOrganization = async (searchId) => {
    const organizations = await getNodeRef("onboarding/organizations").orderByChild("searchID").once("value")

    return organizations.val()
}