import { get } from 'lodash'

export const languages = {
    en: {
        name: "English"
    },
    zh: {
        name: "Simplified Chinese (简体中文)"
    },
    // zht: {
    //     name: "Traditional Chinese (繁体中文)"
    // },
    ta: {
        name: "Tamil (தமிழ்)"
    },
    bn: {
        name: "Bengali (বাংলা)"
    },
    ms: {
        name: "Malay (Bahasa Melayu)"
    }

}

export const s11Blocks = [
    "4",
    "4A",
    "4B",
    "6",
    "6A",
    "6B",
    "6C",
    "6D",
    "6E",
    "6F",
    "6G",
    "6H",
    "6J"
]

export const s11FloorNumbers = [
    "01",
    "02",
    "03",
    "04"
]

export const dormOptions = {
    "PPT Lodge 1B": {
        name: "PPT Lodge 1B"
    },
    "Changi Lodge 2": {
        name: "Changi Lodge 2"
    }
}

export const strings = {
    dormId: {
        en: "Enter your code...",
        ms: "Masukkan code anda...",
        ta: "குறியீட்டை உள்ளிடவும் ...",
        zh: "输入您的代码...",
        zht: "輸入您的代碼...",
        bn: "আপনার কোড লিখুন..."
    },
    submit: {
        en: "Submit",
        ms: "Kirimkan",
        ta: "சமர்ப்பிக்கவும்",
        zh: "提交",
        zht: "提交",
        bn: "জমা দিন"
    },
    details: {
        en: "Enter details to register",
        ms: "Masukkan butiran untuk mendaftar",
        ta: "பதிவு செய்ய விவரங்களை உள்ளிடவும்",
        zh: "输入详细信息进行注册",
        zht: "輸入詳細信息進行註冊",
        bn: "নিবন্ধন করতে বিশদ লিখুন"
    },
    firstName: {
        en: "First Name",
        ms: "Nama pertama",
        ta: "முதல் பெயர்",
        zh: "名字",
        zht: "名字",
        bn: "নামের প্রথম অংশ"
    },
    lastName: {
        en: "Last Name",
        ms: "Nama terakhir",
        ta: "கடைசி பெயர்",
        zh: "姓",
        zht: "姓",
        bn: "নামের শেষাংশ"
    },
    phone: {
        en: "Phone",
        ms: "Telefon",
        ta: "தொலைபேசி எண்",
        zh: "电话",
        zht: "電話",
        bn: "ফোন"
    },
    unit: {
        en: "Room number",
        ms: "Unit",
        ta: "அறை எண்",
        zh: "单位",
        zht: "單位",
        bn: "রুম নম্বর"
    },
    errorUnitNumber: {
        en: "Please enter a valid room number",
        ta: "செல்லுபடியாகும் அறை எண்ணை உள்ளிடவும்",
        ms: "Sila masukkan nombor bilik yang sah",
        zh: "请输入有效的房间号",
        bn: "দয়া করে একটি বৈধ কক্ষ নম্বর লিখুন"
    },
    block: {
        en: "Block Number",
        ms: "Nombor blok",
        ta: "தொகுதி எண்",
        zh: "住宅区号",
        zht: "住宅區號",
        bn: "ব্লক নম্বর"
    },
    prefLang: {
        en: "Preferred language",
        ms: "Bahasa pilihan",
        ta: "விருப்பமான மொழி",
        zh: "首选语言",
        zht: "首選語言",
        bn: "পছন্দের ভাষা"
    },
    thank: {
        en: "Thank you.",
        ms: "Terima Kasih.",
        ta: "நன்றி",
        zh: "谢谢",
        zht: "谢谢",
        bn: "ধন্যবাদ."
    },
    click: {
        en: "Please click here to download",
        ms: "Sila klik di sini untuk memuat turun",
        ta: "பதிவிறக்க இங்கே தட்டவும்",
        zh: "点击此处下载",
        zht: "點擊此處下載",
        bn: "ডাউনলোড করতে এখানে ক্লিক করুন"
    },
    login: {
        en: "and login with your phone number.",
        ms: "dan log masuk dengan nombor telefon anda.",
        ta: "உங்கள் தொலைபேசி எண்ணைப் பயன்படுத்தி உள்நுழைக.",
        zh: "并使用您的电话号码登录。",
        zht: "並使用您的電話號碼登錄。",
        bn: "এবং আপনার ফোন নম্বর দিয়ে লগইন করুন।"
    },
    link: {
        en: "Link",
        ms: "Link",
        ta: "இணைப்பு",
        zh: "网站",
        zht: "網站",
        bn: "লিংক"
    },
    nric: {
        en: "NRIC/FIN Number",
        ms: "Nombor NRIC/FIN",
        ta: "NRIC / FIN எண்",
        zh: "NRIC / FIN编号",
        zht: "NRIC / FIN編號",
        bn: "এনআরআইসি / এফআইএন নম্বর"
    },
    required: {
        en: "Required",
        ms: "Diperlukan",
        ta: "தேவை",
        zh: "必要",
        zht: "必要",
        bn: "প্রয়োজনীয়"
    },
    errorPhone: {
        en: "Please enter a correct phone number",
        ms: "Sila masukkan nombor telefon yang betul",
        ta: "சரியான தொலைபேசி எண்ணை உள்ளிடவும்",
        zh: "请输入正确的电话号码",
        zht: "請輸入正確的電話號碼",
        bn: "দয়া করে একটি সঠিক ফোন নম্বর লিখুন"
    },
    errorNric: {
        en: "Please enter valid last 5 digits of NRIC/FIN or your full NRIC/FIN. For example, if your number is G1234567A, enter 4567A.",
        ms: "Sila masukkan 5 digit terakhir NRIC / FIN atau NRIC / FIN penuh anda. Contohnya, jika nombor anda G1234567A, masukkan G1234567A atau 4567A.",
        ta: "என்.ஆர்.ஐ.சி / ஃபின் அல்லது உங்கள் முழு என்.ஆர்.ஐ.சி / ஃபின் கடைசி 5 இலக்கங்களை உள்ளிடவும். எடுத்துக்காட்டாக, உங்கள் எண் G1234567A எனில், G1234567A அல்லது 4567A ஐ உள்ளிடவும்.",
        zh: "请输入有效的NRIC / FIN的后5位数字或完整的NRIC / FIN。 例如，如果您的电话号码是G1234567A，则输入G1234567A或4567A。",
        zht: "請輸入有效的NRIC / FIN的後5位數字或完整的NRIC / FIN。 例如，如果您的電話號碼是G1234567A，則輸入G1234567A或4567A。",
        bn: "অনুগ্রহ করে এনআরআইসি / এফআইএন বা আপনার সম্পূর্ণ এনআরআইসি / এফআইএন এর শেষ 5 টি সংখ্যা প্রবেশ করান। উদাহরণস্বরূপ, যদি আপনার নম্বর G1234567A হয় তবে G1234567A বা 4567A লিখুন।"
    },
    errorCode: {
        en: "Cannot find your code, please enter again.",
        ms: "Tidak dapat mencari kod anda, sila cuba lagi",
        ta: "உங்கள் குறியீட்டைக் கண்டுபிடிக்க முடியவில்லை, தயவுசெய்து மீண்டும் உள்ளிடவும்.",
        bn: "আপনার কোডটি খুঁজে পাওয়া যায় না, দয়া করে আবার প্রবেশ করুন।",
        zh: "找不到您的验证码，请再次输入。",
        zht: "找不到您的驗證碼，請再次輸入。",
    },
    nricText: {
        en: "Enter last 5 digits of NRIC/FIN or your full NRIC/FIN. For example, if your number is G1234567A, enter G1234567A or 4567A.",
        ms: "Masukkan 5 digit terakhir Kad Pengenalan / FIN atau Kad Pengenalan penuh anda. Contohnya, jika nombor anda G1234567A, masukkan G1234567A atau 4567A.",
        ta: "NRIC / FIN இன் கடைசி 5 இலக்கங்களை அல்லது உங்கள் முழு NRIC ஐ உள்ளிடவும். எடுத்துக்காட்டாக, உங்கள் எண் G1234567A எனில், G1234567A அல்லது 4567A ஐ உள்ளிடவும்.",
        bn: "আপনার এনআরআইসি / এফআইএন বা আপনার সম্পূর্ণ এনআরআইসি / এফআইএন এর শেষ 5 টি সংখ্যা লিখুন। উদাহরণস্বরূপ, যদি আপনার নম্বর G1234567A হয় তবে G1234567A বা 4567A লিখুন।",
        zh: "输入NRIC / FIN的后5位数字或完整的NRIC。例如，如果您的电话号码是G1234567A，则输入G1234567A或4567A。",
        zht: "輸入NRIC / FIN的後5位數字或完整的NRIC。例如，如果您的電話號碼是G1234567A，則輸入G1234567A或4567A。"
    },
    companyName: {
        en: "Company Name",
        bn: "কোমপানির নাম",
        zht: "公司名",
        zh: "公司名",
        ta: "நிறுவனத்தின் பெயர்",
        ms: "Nama syarikat"
    },
    dormitoryEmp: {
        en: "Dormitory",
        ms: "Asrama",
        ta: "தங்குமிடம்",
        zh: "宿舍",
        zht: "宿舍",
        bn: "ছাত্রাবাস"
    },
    phoneEmp: {
        en: "Mobile Number of Company Representative",
        bn: "নিয়োগকারী প্রতিনিধিদের মোবাইল নম্বর",
        ms: "Bilangan Perwakilan Majikan",
        zht: "雇主代表的手機號碼",
        zh: "雇主代表的手机号码",
        ta: "முதலாளி பிரதிநிதியின் மொபைல் எண்"

    },
    firstNameEmp: {
        en: "First Name of Company Representative",
        ta: "முதலாளி பிரதிநிதியின் பெயர்",
        ms: "Nama Wakil Majikan",
        zh: "雇主代表姓名",
        zht: "雇主代表姓名",
        bn: "নিয়োগকারী প্রতিনিধি নাম"
    },
    lastNameEmp: {
        en: "Last Name of Company Representative",
        ta: "முதலாளி பிரதிநிதியின் பெயர்",
        ms: "Nama Wakil Majikan",
        zh: "雇主代表姓名",
        zht: "雇主代表姓名",
        bn: "নিয়োগকারী প্রতিনিধি নাম"
    },
    designationEmp: {
        en: "Designation of Company Representative",
        bn: "নিয়োগকারী প্রতিনিধি পদবী",
        zht: "雇主代表的指定",
        zh: "雇主代表的指定",
        ms: "Pelantikan Wakil Majikan",
        ta: "முதலாளி பிரதிநிதியின் பதவி"
    },
    permitNumber: {
        en: "Permit Number",
        bn: "পারমিট নম্বর",
        zh: "许可证编号",
        ta: "அனுமதி எண்",
        ms: "Nombor izin"
    },
    errorPermit: {
        en: "Please enter a valid permit number",
        ms: "Sila masukkan nombor permit yang sah",
        ta: "செல்லுபடியாகும் அனுமதி எண்ணை உள்ளிடவும்",
        zh: "请输入有效的许可证号",
        bn: "দয়া করে একটি বৈধ অনুমতি নম্বর লিখুন"
    },
    company: {
        en: "Company / Employer Name",
        bn: "সংস্থা / নিয়োগকর্তার নাম",
        zh: "公司/雇主名称",
        ta: "நிறுவனம் / முதலாளியின் பெயர்",
        ms: "Nama Syarikat / Majikan"
    },
    errorBlock: {
        en: "Please enter a valid block number",
        ms: "Sila masukkan nombor blok yang sah",
        ta: "செல்லுபடியாகும் தொகுதி எண்ணை உள்ளிடவும்",
        zh: "请输入有效的密码",
        bn: "দয়া করে একটি বৈধ ব্লক নম্বর লিখুন"
    }
}

export const getString = (field, language) => {
    return get(strings, [field, language], get(strings, [field, "en"], ""))
}

